import React from 'react';

import styles from './Header.module.css';

const GlossaryHeader = props => {
  return (
    <>
      <div className={styles.glossaryTitle}>Glossary</div>
    </>
  );
};

export default GlossaryHeader;
