import React from 'react';

import styles from './MethodologyHeader.module.css';

const MethodologyHeader = props => {
  return (
    <>
      <div className={styles.methodologyTitle}>Methodology</div>
    </>
  );
};

export default MethodologyHeader;
