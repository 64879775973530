import React from 'react';

import styles from './AboutHeader.module.css';

const AboutHeader = props => {
  return (
    <>
      <div className={styles.aboutTitle}>About</div>
    </>
  );
};

export default AboutHeader;
